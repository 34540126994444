import { useState } from "react";
import { Link, useLoaderData } from "@remix-run/react";
import Anchor from "../ui/Anchor";
import Button from "../ui/Button";
import Container from "./Container";
import Cake from "../icons/Cake";
import Logo from "../icons/Logo";


function formatNumber(number) {
  number = parseInt(number);

  let formattedNumber = '';

  if (number >= 1000000000) {
    formattedNumber = (number / 1000000000).toFixed(2) + 'B';
  } else if (number >= 1000000) {
    formattedNumber = (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 100000) {
    formattedNumber = (number / 1000).toFixed(0).toLocaleString() + 'K';
  } else {
    formattedNumber = number.toLocaleString();
  }

  return formattedNumber;
}

export default function Header({
  home = false,
  showPitch = false,
  posting = false,
  afterPostSuccess = false,
  afterPostFailure = false,
  name = false,
  status = 0,
  picture = false,
  session = null,
}) {
  // Use useLoaderData hook to get loader data
  const loaderData = useLoaderData();



  name = name === "DEBUG_MODE" ? '' : name;
  // State to track hover state of logout button
  const [logoutHover, setLogoutHover] = useState(false);
  // Function to handle logout
  const handleLogout = () => {
    window.location.href = '/logout';
  };

  const handleProfile = () => {
    window.location.href = '/profile';
  };
  const handleContact = () => {
    window.location.href = '/contact';
  };


  return (
    <div className={`fade-in border-b border-neutral-800/50 bg-white/5 py-4 flex flex-col items-stretch justify-start gap-8 header-region ${!showPitch ? 'no-pitch' : ''}`}>
      {afterPostSuccess ? (
        <Container className="flex flex-row items-stretch justify-start">
          <div className="flex-1 flex flex-row items-center justify-center gap-2 bg-green-500/20 p-2 rounded-md">
            <Cake />
            <p className="font-medium text-sm">
              Congratulations! Your posts are live now.
            </p>
          </div>
        </Container>
      ) : null}
      {afterPostFailure ? (
        <Container className="flex flex-row items-stretch justify-start">
          <div className="flex-1 flex flex-row items-center justify-center gap-2 bg-red-500/20 p-2 rounded-md">
            <p className="font-medium text-sm">Oops! Something went wrong.</p>
          </div>
        </Container>
      ) : null}
      <Container>
        <ul className="flex flex-row items-center justify-end gap-2">
          {home ? null : (
            <li>
              <Button as={Link} to="/" ghost>
                Home
              </Button>
            </li>
          )}
          <li>
            {name && (
              <span
                className="flex-1 flex flex-row gap-2 font-bold flex text-2xs cursor-pointer"
                onMouseEnter={() => setLogoutHover(true)} // Set logoutHover state to true on mouse enter
                onMouseLeave={() => setLogoutHover(false)} // Set logoutHover state to false on mouse leave
              >
                {logoutHover && ( // Render logout button when logoutHover is true
                  <>
                    <Button className="logout-button fade-in" onClick={() => handleLogout()} ghost>
                      Logout
                    </Button>
                    <Button className="contact-button pulse fade-in" onClick={() => handleContact()} ghost>
                      Contact
                    </Button>
                    <Button className="profile-button pulse fade-in" onClick={() => handleProfile()} ghost>
                      Profile
                    </Button>

                  </>
                )}
                {!logoutHover && ( // Render logout button when logoutHover is true
                  <span>Welcome</span>
                )}

                <span className="user-name flex flex-row gap-2 font-bold flex text-2xs bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent">
                  {" "}
                  {name}{" "}
                </span>
                <img
                  alt="profile"
                  className="user-picture flex-1 flex flex-row items-stretch justify-end gap-2"
                  src={picture}
                ></img>
                {loaderData.status === 1 && (
                  <a
                    href="/payment"
                    className="bigYellow whitespace-nowrap font-medium text-sm text-center text-white border border-opacity-50 px-3 py-1 rounded-md block border-yellow-400 bg-yellow-500 hover:border-yellow-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 flex-1"
                    type="cancel"
                  >
                    Upgrade to PRO
                  </a>
                )}

              </span>
            )}
            {!name && (
              <form action="/auth/auth0" method="post">
                <Button className="login pulse">Login</Button>
              </form>
            )}
          </li>
        </ul>
      </Container>
      <Container
        as="nav"
        className="flex flex-row items-center justify-center gap-1"
      >
        <Anchor href="/" styled={false}>
          <ul className="flex flex-row items-center justify-between gap-3">
            <li >
              <Logo />
            </li>
          </ul>
        </Anchor>
      </Container>
      {
        showPitch ? (
          <Container
            as="header"
            className="light-border-space content flex flex-col items-stretch justify-start gap-1"
          >
            <div className="flex flex-row items-start justify-between flex-wrap gap-4 ">
              <div className="flex flex-col items-stretch justify-start gap-1">
                <h1 className="font-bold text-2xl bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent content">
                  Find optimized AI driven waypoints to make your journey more affordable
                </h1>
                <p className="text-neutral-400 content-paragraph-dark-content content thin">
                  Want to fly to a destination but can't find an affordable direct flight? Why not save money by finding a Waypoint. Our system can find you the cheapest connecting city to your final destination, and allows you to explore another location for less than it would have cost to deal with a boring layover. Stop and hang out in a new city and continue on your way a few days later.&nbsp;
                  <Anchor className="text-white" as={Link} to="/about/how">
                    <span className="emoji">🎓📚🌍</span> <span className="content">Learn more about how it works. </span><span className="emoji">✈️🤯🧠</span>
                  </Anchor>
                </p>
              </div>
            </div>
            <div className="mt-4 flex flex-row items-stretch justify-start gap-4 paragraph">
              <div className="flex flex-col items-stretch justify-start">
                <p className="font-medium text-lg content header-label">{formatNumber(loaderData?.airports)}</p>
                <p className="text-sm text-neutral-400 content">Airports</p>
              </div>
              <div className="flex flex-col items-stretch justify-start paragraph">
                <p className="font-medium text-lg content header-label">{formatNumber(loaderData?.flights)}</p>
                <p className="text-sm text-neutral-400 content">Flights</p>
              </div>
              <div className="flex flex-col items-stretch justify-start paragraph">
                <p className="font-medium text-lg content header-label">{formatNumber(loaderData?.users)}</p>
                <p className="text-sm text-neutral-400 content">Waypoint Travelers</p>
              </div>
              <div className="flex flex-col items-stretch justify-start paragraph">
                <p className="font-medium text-lg content header-label">{formatNumber(loaderData?.waypoints)}</p>
                <p className="text-sm text-neutral-400 content">Waypoints Generated</p>
              </div>
            </div>
            <div className="mt-4 flex flex-row items-stretch justify-start gap-4">
              <div className="flex flex-col items-stretch justify-end">
                <p className="font-medium text-lg text-red-500">Attention!</p>
                <div className="font-small">
                  <p className="message">
                    <span className="emoji">🤑🤑🤑</span>
                    <span className="content">UPDATE ADVERTISEMENT: Need to hire? Robots don't lie. Hire with Super Recruiters. <a target="_blank" className="promo-link" href="https://superrecruiters.ai">superrecruiters.ai</a> PRO Accounts only $0.05 until launch. Afterwards 30 bucks, maybe. Who knows.</span>
                    <span className="emoji">🤑🤑🤑</span>
                  </p>
                </div>
              </div>
            </div>
          </Container>
        ) : null
      }
    </div >
  );
}
