import { Link } from "@remix-run/react";

import Anchor from "../ui/Anchor";
import Container from "./Container";

export default function Footer() {
  return (
    <div className="footer content border-t border-neutral-800/50 bg-white/5 py-4 flex flex-col items-stretch justify-start">
      <Container
        as="footer"
        className="flex flex-col items-stretch justify-start gap-2"
      >
        <ul className="flex flex-row items-center justify-center gap-2 text-xs">
          <li className="">
            <Anchor as={Link} to="/legal/privacy">
              Privacy policy
            </Anchor>
          </li>
          <li className="">
            <Anchor as={Link} to="/contact">
              Contact Us
            </Anchor>
          </li>
        </ul>
        <p className="text-xs text-center text-neutral-400">
          &copy; Waypoints, 2024 -<a target="_blank" href="https://nexus-platforms.com">  [ Nexus Platforms ] </a>- <a target="_blank" href="https://runitbyq.com"> Got an Idea? Run it by Q!</a>
        </p>
      </Container>
    </div>
  );
}
